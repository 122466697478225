/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-08 10:25:21
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-08 12:29:49
 * @Description: desc
 */
import React from 'react';

import NavBar from '../../components/navBar/navBar';
import Footer from '../../components/footer/footer';
import CourseSystem from '../../components/courseSystem';

import './style/index.css';
import Intro from './components/mainPartone/mainPartOne';
import AboutUs from '../../components/aboutUs/aboutUs';
import OurService from '../../components/ourService/ourService';
import Ours from '../../components/fourOurs';
import SplitLine from '../../components/splitLine/mainPartThree';
import MainParts from './components/mainPartFour/mainPartFour';
const CONSTANTS = require('../../lib/constants');
const {
    Our,
    Title,
    Text,
    aboutUsArticle,
    OurServiceArticle,
} = CONSTANTS.default;

class Home extends React.Component {
    state = {
        addBackground: false,
    };

    componentDidMount() {
        window.onscroll = () => {
            if (window.pageYOffset > 780) {
                this.setState({
                    addBackground: true,
                });
            } else {
                this.setState({
                    addBackground: false,
                });
            }
        };
    }

    render() {
        return (
            <div className="home">
                <div className="bar">
                    <NavBar
                        addBackground={this.state.addBackground}
                        pageIndex={'home'}
                    />
                    <Intro />
                </div>
                <div className="main">
                    <AboutUs aboutArticle={aboutUsArticle} />
                    <div className="four-ours-container">
                        <Ours oursMessage={Our} />
                    </div>
                    <SplitLine title={Title[3]} />
                    <OurService ourServiceArticle={OurServiceArticle} />
                    <SplitLine title={Title[0]} />
                    <div className="main-part-one">
                        <div>
                            <MainParts
                                title={Text[0].title}
                                text={Text[0].text}
                                img={Text[0].img}
                            />
                            <MainParts
                                title={Text[1].title}
                                text={Text[1].text}
                                img={Text[1].img}
                            />
                        </div>
                        <div>
                            <MainParts
                                title={Text[2].title}
                                text={Text[2].text}
                                img={Text[2].img}
                            />
                            <MainParts
                                title={Text[3].title}
                                text={Text[3].text}
                                img={Text[3].img}
                            />
                            <MainParts
                                title={Text[4].title}
                                text={Text[4].text}
                                img={Text[4].img}
                            />
                        </div>
                    </div>
                    <SplitLine title={Title[1]} />
                    <div className="enlightenment-container">
                        <img src="/images/course-tree.jpg" alt="" />
                    </div>
                    <SplitLine title={Title[2]} />
                    <CourseSystem />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Home;
