/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-08 10:25:21
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-08 12:38:41
 * @Description: desc
 */
import React from 'react';
import './ourService.css';
interface ourServiceArticle {
    ourServiceArticle: Array<string>;
}
const AboutUs = (props: ourServiceArticle) => (
    <div className="our-service-container">
        <article>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {props.ourServiceArticle[0]}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.ourServiceArticle[1]}
        </article>
    </div>
);

export default AboutUs;
