/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-09 09:41:25
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-09 10:54:08
 * @Description: desc
 */
import React from 'react';
import './mainPartFour.css';

interface obj {
    title: string;
    text: string;
    img: string;
}

const MainParts = (props: obj) => (
    <div className="why-choose-mmmy-container">
        <div className="revers-container">
            <div className="why-choose-mmmy">
                <div className="front">
                    <img src={props.img} alt="" />
                </div>
                <div className="back">
                    <article className="text">{props.text}</article>
                </div>
            </div>
        </div>
        <p className="revers-front"> {props.title}</p>
    </div>
);

export default MainParts;
