import React from 'react';
import './mainPartThree.css';
interface splitLineTitle {
    title: string;
}
const SplitLine = (props: splitLineTitle) => (
    <div className="split-line">
        <img src="/images/line-right.png" alt="" />
        <p>{props.title}</p>
        <img src="/images/line-left.png" alt="" />
    </div>
);

export default SplitLine;
