import React from 'react';
import './index.css';
import { BiPhone } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { GiPositionMarker } from 'react-icons/gi';
interface contactUs {
    address: string;
    email: string;
    phoneNumber: string;
    URL: string;
}
interface contactMessage {
    contactMessage: contactUs;
}
const ContactUs = (props: contactMessage) => (
    <div className="about-us-container">
        <div className="title">
            <p className="title-1">联系我们</p>
            <p className="title-2">CONTACT</p>
            <p className="title-2">US</p>
        </div>
        <div className="line"></div>
        <div className="address-container">
            <div className="detail-address">
                <p>
                    {' '}
                    <GiPositionMarker />
                    <i> 地址：{props.contactMessage.address}</i>
                </p>
                <p>
                    {' '}
                    <HiOutlineMail />
                    <i>邮箱：{props.contactMessage.email}</i>
                </p>
                <p>
                    <BiPhone />{' '}
                    <i>联系方式：{props.contactMessage.phoneNumber}</i>
                </p>
            </div>
            <img
                src={props.contactMessage.URL}
                alt=""
                className="address-img"
            />
        </div>
    </div>
);

export default ContactUs;
