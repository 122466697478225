import React from 'react';
import './index.css';

interface ourItem {
    src: string;
    title: string;
    description: string;
}
interface oursMessage {
    oursMessage: Array<ourItem>;
}
const Ours = (props: ourItem) => (
    <div className="ours">
        <div className="img-container">
            <img src={props.src} alt="" />
        </div>
        <p>{props.title}</p>
        <article>{props.description}</article>
    </div>
);
const FourOurs = (props: oursMessage) => (
    <div className="four-ours">
        {props.oursMessage.map((item: ourItem) => (
            <Ours
                key={item.src}
                src={item.src}
                title={item.title}
                description={item.description}
            />
        ))}
    </div>
);

export default FourOurs;
