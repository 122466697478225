/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-08 10:25:21
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-08 12:22:14
 * @Description: desc
 */
import React from 'react';
import './aboutUs.css';
interface aboutArticle {
    aboutArticle: Array<string>;
}
const AboutUs = (props: aboutArticle) => (
    <div className="about-us-container">
        <div className="title">
            <p className="title-1">关于我们</p>
            <p className="title-2">ABOUT</p>
            <p className="title-2">US</p>
        </div>
        <div className="line"></div>
        <article>
            {props.aboutArticle[0]}
            <br />
            <br />
            {props.aboutArticle[1]}
        </article>
    </div>
);

export default AboutUs;
