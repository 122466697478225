import React from 'react';

import './App.css';
import Routes from '../config/routes';

import { BrowserRouter } from 'react-router-dom';

const App = () => (
    <BrowserRouter>
        <Routes />
    </BrowserRouter>
);
export default App;
