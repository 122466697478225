import React from 'react';
import './index.css';
const CONSTANTS = require('../../../lib/constants');
const { NavMessage } = CONSTANTS.default;
const NavAboutUs = () => (
    <div className="nav-about-us-container">
        <div className="nav-about-us">
            <div className="about-us-Intro">
                <h2>{NavMessage[0]}</h2>
                <div className="split"></div>
                <h3>{NavMessage[1]}</h3>
            </div>
        </div>
    </div>
);

export default NavAboutUs;
