import config from '../containers/config';

import { IMenu, IRoute } from '../models/common';

interface IExportDate {
    menus: Array<IMenu>;
    routes: Array<IRoute>;
}

const { modules } = config;

const exportData: IExportDate = {
    menus: [],
    routes: [],
};

modules.forEach(moduleName => {
    const { menu, routes } = require(`../containers/${moduleName}/config`);
    if (menu) {
        exportData.menus.push(menu);
    }
    if (routes) {
        exportData.routes = exportData.routes.concat(routes);
    }
});

export default exportData;
