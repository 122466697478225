/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-08 10:25:21
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-08 17:22:39
 * @Description: desc
 */
import React from 'react';
import './mainPartOne.css';

const Intro = () => (
    <div className="paragraph-container">
        <div className="paragraph">
            <img
                style={{ width: '24em', maxWidth: '60vw' }}
                src="/images/banner-slogan.png"
                alt=""
            />
            <p className="slogan">
                /&nbsp;&nbsp;&nbsp;最 好 的 启 蒙 教 育 源 于 家
                庭&nbsp;&nbsp;&nbsp;/
            </p>
        </div>
        <div className="video-container">
            <video autoPlay loop muted>
                <source src="/banner-video.mp4" />
            </video>
        </div>
    </div>
);

export default Intro;
