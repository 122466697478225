import React from 'react'
import './footer.css'

const Footer = () => (
    <footer>
        <div className='footer'>
            <div className="logo">
                <img title='妈妈美语' src='/images/logo-font-vertical.png' />
            </div>
            <div className="footer-guide-container">
                <ul>
                    <li> <div className="footer-split-line"></div></li>
                    <li className='footer-guide'><a href="/">首页</a></li>
                    <li><div className="footer-split-line"></div></li>
                    <li className='footer-guide'><a href="/courseSystem">课程体系</a></li>
                    <li><div className="footer-split-line"></div></li>
                    <li className='footer-guide'><a href="/minisApp">小程序</a></li>
                    <li><div className="footer-split-line"></div></li>
                    <li className='footer-guide'><a href="/aboutUs">关于我们</a></li>
                    <li><div className="footer-split-line"></div></li>
                </ul>
            </div>
            <div className="QR-code-container">
                <div className="QR-code">
                    <img src="/images/QR-code-mini.png" alt="" />
                    <p>妈妈美语小程序</p>
                </div>
                <div className="QR-code">
                    <img src="/images/QR-code-official.jpg" alt="" />
                    <p>妈妈美语公众号</p>
                </div>
            </div>
        </div>

        <div className="footer-copy">
            <p>
                备案/许可证编号:
                <a href="http://beian.miit.gov.cn" target="_blank" >
                    陕ICP备2020017395号
                </a>
            </p>
            <a target="_blank"
                className="police-link"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001478">
                <img src="/images/police.png" className="police-item" />
                <p className="police-item">陕公网安备61019002001478号</p>
            </a>
        </div>

    </footer>
)

export default Footer
