import React from 'react';
import Nav from '../../components/navBar/navBar';
import Footer from '../../components/footer/footer';
import One from '../../components/aboutUs/aboutUs';
import Two from '../../components/fourOurs';
import Three from './contactUS';
import NavAboutUs from './introMessage';
import './index.css';
const CONSTANTS = require('../../lib/constants');
const { Our, contactMessage, aboutUsArticle } = CONSTANTS.default;
const AboutUs = () => (
    <div>
        <Nav addBackground={true} pageIndex={'aboutUs'} />
        <div className="partOne">
            <NavAboutUs />
        </div>
        <One aboutArticle={aboutUsArticle} />
        <div className="ours-container">
            <Two oursMessage={Our} />
        </div>
        <Three contactMessage={contactMessage} />
        <div className="footer-container">
            <Footer />
        </div>
    </div>
);

export default AboutUs;
