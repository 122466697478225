/*
 * @Author: Beck Qin(enginebeck@gmail.com)
 * @Date: 2022-03-08 10:25:21
 * @LastEditors: Beck Qin(enginebeck@gmail.com)
 * @LastEditTime: 2022-03-09 10:44:58
 * @Description: desc
 */
export default {
    // Global
    SET_CURRENT_ADMIN: 'global/set_current_admin',
    SET_LOADING: 'global/set_loading',
    SET_DEFAULT_VERSION: 'global/default_version',

    // Pagination
    DefaultPagination: {
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    },
    DefaultDrawer: {
        isCreate: true,
        title: '',
        type: '',
    },
    aboutUsArticle:
        ['“MMMY” 专注于为0-3岁婴幼儿的家长提供科学、专业、系统的家庭教育指导服务。', '我们倡导“最好的启蒙教育源于家庭”的理念，将线上平台趣味赋能与线下家庭沉浸式体验相结合。以亲子互动、素质教育为基础，以科学育儿、语言启蒙为主导，根据婴幼儿的年龄特征与身心发展变化，从运动、语言、认知、情感四大领域出发，有针对性的为家长研发科学完备的原创课程；打造优质家庭教育经验分享的平台；提供专业系统性的家庭教育指导服务。旨在协助家长化解家庭启蒙教育阶段焦虑的问题，为每一位宝宝携手营造优质的家庭成长环境。'],
    OurServiceArticle: [
        '“妈妈美育”———最值得您信赖的家庭教育指导平台。我们为家长提供专业的启蒙指导服务，协助更多家长实现优质启蒙，丰富您与宝宝的日常互动。提供“云”空间，让您轻松记录宝宝成长的精彩瞬间。同时，我们设立了公益窗口，您的爱心直达福利院，愿所有的孩子都能健康快乐地成长。',
        '“妈妈美语”———我们根据婴幼儿的身心发育特征，为家长研发真正贴近宝宝成长规律，真正符合中国家庭成长环境，更趣味实效的双语启蒙课程，零基础的家长也可以轻松驾驭。通过指导家长自我提升，进而实现家长启蒙宝宝习得英语，让宝宝接收到最亲近、自然的语言启蒙，共同打造优质的亲子成长环境。'
    ],
    Our: [
        {
            src: '/images/brief-mission.png',
            title: '我们的使命',
            description: '帮助更多家庭营造优质的亲子成长环境',
        },
        {
            src: '/images/brief-vision.png',
            title: '我们的愿景',
            description: '成为您身边最可信任的家庭教育指导师',
        },
        {
            src: '/images/brief-idea.png',
            title: '我们的理念',
            description: '最好的启蒙教育源于家庭',
        },
        {
            src: '/images/brief-value.png',
            title: '我们的价值观',
            description: '仁爱、科学、根植素质教育',
        },
    ],
    Text: [
        {
            title: '科学完备的课程体系',
            img: '/images/why-course.png',
            text:
                '我们拥有专业的创作团队，基于国内外教育专家的理论研究，结合婴幼儿的发育特征，以线上平台趣味性与线下家庭沉浸式体验相结合的方式，研发了最系统的家庭教育指导方案。',
        },
        {
            title: '质优高效的亲子时光',
            img: '/images/why-together.png',
            text:
                '根据婴幼儿的身心发展特征，从运动、语言、认知、情感四大领域给予家长专业的指导。通过持续的有效陪伴，提升您与宝宝亲子时光的质量。',
        },
        {
            title: '贴近生活的语言启蒙',
            img: '/images/why-common.png',
            text:
                '在内容设计时我们通过挖掘大量高频率、生活化用语，从而实现在日常生活中即可潜移默化地培养宝宝倾听理解和语言表达的能力。',
        },
        {
            title: '益于身心的成长环境',
            text:
                '家庭环境的良好氛围，对宝宝的身心成长起着至关重要的作用，通过系统性的指导，携手家长共同为宝宝打造优质的成长环境，让每一位宝宝在健康的家庭氛围下快乐成长。',
            img: '/images/why-zero.png',
        },

        {
            title: '开放益智的实物教具',
            img: '/images/why-toy.png',
            text:
                '教具设计以亲近自然为基础，以教育开放性为原则，鼓励孩子勇于创新、大胆尝试，助力实现益智启蒙。',
        },
    ],
    Title: ['为什么选择妈妈美语', '不仅仅是语言启蒙', '课程体系', '我们的服务'],

    contactMessage: {
        address: '西安市雁塔区科技一路万象汇3号楼902',
        email: 'Info@erealmsoft.com',
        phoneNumber: '+8618092009802',
        URL: '/images/our-location.jpg',
    },
    NavMessage: ['我们的使命', '帮助更多家庭营造优质的亲子成长环境'],
};
