import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import dataSet from './dataSet';

const Routes = () => {
    return (
        <Switch>
            {dataSet.routes.map((route: any) => {
                return (
                    <Route
                        exact
                        key={route.link}
                        path={route.link}
                        component={route.component}
                    />
                );
            })}
            <Redirect to="/" />
        </Switch>
    );
};

export default Routes;
