import React from 'react';
import Nav from '../../components/navBar/navBar';
import SplitLine from '../../components/splitLine/mainPartThree';
import Footer from '../../components/footer/footer';
import PartOne from './partOne/partOne';
import CourseSystem from '../../components/courseSystem';
const Course = () => (
    <div>
        <Nav addBackground={true} pageIndex={'courseSystem'} />
        <PartOne />
        <SplitLine title={'妈妈美语课程体系'} />
        <CourseSystem />
        <Footer />
    </div>
);

export default Course;
