/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './navbar.css';
interface addBackground {
    addBackground: boolean;
    pageIndex: string;
}
class NavBar extends React.Component<addBackground> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    state = {
        addBackground: this.props.addBackground,
        pageIndex: this.props.pageIndex,
    };
    componentDidMount() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        switch (this.state.pageIndex) {
            case 'home':
                Object(this.refs.mainPage).className = 'show-tag';
                Object(this.refs.mainPageColor).className = 'focusColor';
                break;
            case 'courseSystem':
                Object(this.refs.CourseSystem).className = 'show-tag';
                Object(this.refs.CourseSystemColor).className = 'focusColor';
                break;
            case 'minisApp':
                Object(this.refs.miniApp).className = 'show-tag';
                Object(this.refs.miniAppColor).className = 'focusColor';
                break;
            case 'aboutUs':
                Object(this.refs.aboutUs).className = 'show-tag';
                Object(this.refs.aboutUsColor).className = 'focusColor';
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div
                className="nav-container"
                style={{
                    background: this.props.addBackground ? '#fff' : 'none',
                }}
            >
                <nav>
                    <div className="logo">
                        <a href="/">
                            <img
                                title="妈妈美语"
                                src="/images/logo-font-level.png"
                            />
                        </a>
                    </div>
                    <div className="guide">
                        <ul>
                            <li>
                                <img
                                    src="/images/love.png"
                                    alt=""
                                    ref={'mainPage'}
                                />
                                <a
                                    href="/"
                                    ref={'mainPageColor'}
                                    style={{
                                        color: this.props.addBackground
                                            ? '#333'
                                            : '#fff',
                                    }}
                                >
                                    {' '}
                                    首页
                                </a>
                            </li>
                            <li>
                                <img
                                    src="/images/love.png"
                                    alt=""
                                    ref={'CourseSystem'}
                                />
                                <a
                                    href="/courseSystem"
                                    ref={'CourseSystemColor'}
                                    style={{
                                        color: this.props.addBackground
                                            ? '#333'
                                            : '#fff',
                                    }}
                                >
                                    课程体系
                                </a>
                            </li>
                            <li className="minis-app">
                                <a
                                    className="show-qr-code"
                                    ref={'miniAppColor'}
                                    style={{
                                        color: this.props.addBackground
                                            ? '#333'
                                            : '#fff',
                                    }}
                                >
                                    小程序
                                </a>
                                <div className="qr-code">
                                    <img src="/images/QR-code-mini.png" alt="" />
                                    <p>微信扫描二维码</p>
                                    <p>立即体验妈妈美语小程序</p>
                                </div>
                            </li>
                            <li>
                                <img
                                    src="/images/love.png"
                                    alt=""
                                    ref={'aboutUs'}
                                />
                                <a
                                    href="/aboutUs"
                                    ref={'aboutUsColor'}
                                    style={{
                                        color: this.props.addBackground
                                            ? '#333'
                                            : '#fff',
                                    }}
                                >
                                    关于我们
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="nav-mask"></div>
            </div>
        );
    }
}

export default NavBar;
